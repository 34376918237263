import React from "react";
import dayjs from "dayjs";
import { Container } from "../../components";
import { OverallTicketSales } from "./components";
import { TotalEvents, PastEvents, UpcomingEvents } from "./components";
import RecentEventList from "./components/RecentEventList";
import SalesReport from "./components/SalesReport";
import Calendar from "./components/Calendar";
import { useGetPartnerDashboardQuery } from "../../services/partner.api";

const DashboardPage: React.FC = () => {
  const { data } = useGetPartnerDashboardQuery({});
  return (
    <div className="flex-grow p-6  bg-[#E8EDFB]">
      <div className="flex flex-col mb-6 md:gap-5 md:justify-between md:flex-row">
        <div className="text-center lg:col-span-4 md:text-start">
          <h1 className="text-5xl font-[500]">Welcome, John Doe</h1>
          <p className="text-2xl font-[500]">Description</p>
        </div>
        <div className="flex flex-col items-center justify-between md:mb-4 md:gap-5 md:flex-row">
          {/* <button className="px-6 py-3 text-xl text-white rounded-md bg-blue-1100">
            + Create Event
          </button> */}
          <span className="text-2xl font-semibold">
            {dayjs().format("hh:MM A")}
          </span>
        </div>
      </div>
      <div className="grid gap-6 lg:grid-cols-4 ">
        <div className="lg:col-span-3">
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
            <Container className="flex items-center p-4 min-h-[180px]">
              <TotalEvents totalEvents={data?.totalEvents || 0} />
            </Container>
            <Container className="flex items-center p-4 min-h-[180px]">
              <PastEvents pastEvents={data?.pastEvents || 0} />
            </Container>
            <Container className="flex items-center p-4 min-h-[180px]">
              <UpcomingEvents upcomingEvents={data?.upcomingEvents || 0} />
            </Container>
            <Container
              variant="secondary"
              className="flex flex-col lg:flex-row lg:col-span-3 min-h-[180px] text-white py-4 px-8 items-center"
            >
              <OverallTicketSales overAllSales={data?.overAllSales || 0} />
            </Container>
            {/* <Container className="flex flex-col lg:col-span-3 min-h-[444px] py-4 px-8 items-center">
              <h1 className="w-full mb-10 text-2xl text-primary-500">
                Sales Graph
              </h1>
              <SalesReport />
            </Container> */}
          </div>
        </div>
        <div className="grid gap-4">
          <Container className="min-h-[340px] p-4">
            <RecentEventList recentEvents={data?.recentEvents || []}/>
          </Container>
          <Container className="min-h-[280px]">
            <Calendar />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
