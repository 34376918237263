import React from "react";
import TicketNationLogo from "../../assets/images/TN.png";
import SignIn from "../../features/Authentication/SignIn";
import SigninImage from "../../assets/images/signin-pic.png";
import { CustomError, useSigninMutation } from "../../services/auth.api";
import { useDispatch } from "react-redux";
import { SignInFormValues } from "../../interfaces/auth/Interface";
import Loading from "../../components/Loading";
import {
  setUser,
  setUserDetail,
} from "../../features/Authentication/authSlice";

import { Link, useNavigate } from "react-router-dom";
import Error from "../../components/Error";

const SignInPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signin, { data, isSuccess, isLoading, error, isError }] =
    useSigninMutation();
  const [userDetails, setUserDetails] = React.useState<SignInFormValues>({});

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      localStorage.setItem("refreshToken", data.refreshToken);
      dispatch(
        setUser({
          refreshToken: data.refreshToken,
          accessToken: data.accessToken,
        })
      );
    }
  }, [isSuccess, isLoading, data, dispatch]);

  React.useEffect(() => {
    if (isError && (error as CustomError).data.isActive === false) {
      dispatch(setUserDetail(userDetails));
      navigate("/verification");
    }
  }, [isError, error, navigate, dispatch, userDetails]);

  if (isLoading) {
    return <Loading />;
  }

  const handleSubmit = (values: SignInFormValues) => {
    signin(values);
    setUserDetails(values);
  };

  return (
    <div className="flex items-center justify-center">
      <div className="w-full md:w-2/5">
        <div className="flex flex-col items-center justify-center">
          <div className="flex justify-center my-12">
            <Link to="/">
              <img src={TicketNationLogo} alt="ticketnation" />
            </Link>
          </div>
          <div>
            <SignIn signInhandleSubmit={handleSubmit} />
            <div className="mr-6 text-center text-red-500">
              {isError && (error as CustomError).data.message}
            </div>
          </div>
        </div>
      </div>

      <div className="relative h-screen md:w-3/5 bg-blue-1100">
        <img
          className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
          src={SigninImage}
          alt="signin-pic"
        />
      </div>
    </div>
  );
};

export default SignInPage;
