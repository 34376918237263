import React from "react";

function ChangePassword() {
  return (
    <div className="w-full p-4">
      <div className="text-xl font-semibold">Old Password</div>
      <div className="mt-2 font-bold text-md">
        <input type="text" className="w-full p-2 mt-2 rounded-md shadow-md" />
      </div>
      <div className="mt-2 text-xl font-semibold">New Password</div>
      <div>
        <input type="text" className="w-full p-2 mt-2 rounded-md shadow-md" />
      </div>
      <div className="mt-2 text-xl font-semibold">Confirm New Password</div>
      <div>
        <input type="text" className="w-full p-2 mt-2 rounded-md shadow-md" />
      </div>
      <div>
        <button className="w-full p-2 mt-4 text-center text-white bg-blue-1100">
          Save New Password
        </button>
      </div>
    </div>
  );
}

export default ChangePassword;
