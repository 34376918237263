import React from "react";
import dayjs from "dayjs";
import { Icon } from "ts-react-feather-icons";
import { Group } from "../../../models/group.model";
import { Performer } from "../../../models/performer.model";
import * as Yup from "yup";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Label } from "../../../components/Common/Label";
import { Input } from "../../../components/Common/Input";
import DatePicker from "../../../components/Common/DatePicker";
import { Event } from "../../../models/event.model";

interface ComponentProps {
  data: Event;
}

interface FormValues {
  dateTime: Date | string;
  name: string;
  venue: string;
  performers: Performer[];
  groups: Group[];
  description: string;
}

export const EditEventForm: React.FC<ComponentProps> = ({ data }) => {
  const {
    name,
    dateTime,
    description,
    groups,
    performers,
    venue: { name: venue },
  } = data;

  const initialValues: FormValues = {
    dateTime: new Date(dateTime) || "",
    name: name || "",
    venue: venue || "",
    performers: [],
    groups: [],
    description: description || "",
  };

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    // onSubmit(values);
  };

  return (
    <div className="grid grid-flow-row px-4">
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {() => (
          <Form>
            <div className="grid row-span-1 grid-cols-5">
              <div className="grid col-span-4">
                <Label htmlFor="dateTime">Date and Time</Label>
                <Field name="dateTime">
                  {({ field }: FieldProps) => (
                    <DatePicker
                      name="dateTime"
                      placeholder="ex. 12/20/2022 06:00PM"
                      minDate={new Date()}
                      className="pr-5"
                    />
                  )}
                </Field>
                <Label htmlFor="name">Name</Label>
                <Field name="name">
                  {({ field }: FieldProps) => (
                    <Input
                      divClass="pr-5"
                      placeholder="ex. Ticket Nation Grand Launch"
                      id="name"
                      {...field}
                    />
                  )}
                </Field>
                <Label htmlFor="venue">Venue</Label>
                <Field name="venue">
                  {({ field }: FieldProps) => (
                    <Input
                      divClass="pr-5"
                      placeholder="ex. Ticket Nation Grand Launch"
                      id="venue"
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div>
                <div className="grid col-span-1 pt-5 gap-3">
                  <div className="flex items-center justify-center mr-4 overflow-hidden bg-black rounded w-full h-[120px]">
                    <div className="bg-black " />
                  </div>
                  <button
                    onClick={() => {}}
                    className="flex justify-center font-medium items-center h-[43px] gap-2 px-4 py-2 text-shades-50 rounded-md bg-primary-500"
                  >
                    <Icon size="18px" name="edit" />
                    Edit
                  </button>
                </div>
              </div>
            </div>
            <div className="grid gap-3 py-2">
              <div className="grid gap-3">
                <>
                  <span className="text-xs">Main Performer</span>
                  <div className="flex flex-wrap gap-2">
                    {performers &&
                      performers.map((performer) => (
                        <div className="flex justify-center w-fit font-medium items-center text-sm gap-2 px-4 py-2 text-shades-50 rounded-full bg-primary-500">
                          <>
                            {/* {performer} */}
                            <button>
                              <Icon size="18px" name="x" />
                            </button>
                          </>
                        </div>
                      ))}
                    <button className="flex justify-center w-fit font-medium items-center text-sm gap-2 px-4 py-2 text-shades-50 rounded-full bg-primary-500">
                      <Icon size="18px" name="plus" />
                      Add Performer
                    </button>
                  </div>
                </>
              </div>
              <div className="grid gap-3 ">
                <>
                  <span className="text-xs">Performers</span>
                  <div className="flex flex-wrap gap-2">
                    {performers &&
                      performers.map((performer) => (
                        <div className="flex justify-center w-fit font-medium items-center text-sm gap-2 px-4 py-2 text-shades-50 rounded-full bg-primary-500">
                          <>
                            {/* {performer} */}
                            <button>
                              <Icon size="18px" name="x" />
                            </button>
                          </>
                        </div>
                      ))}
                    <button className="flex justify-center w-fit font-medium items-center text-sm gap-2 px-4 py-2 text-shades-50 rounded-full bg-primary-500">
                      <Icon size="18px" name="plus" />
                      Add Performer
                    </button>
                  </div>
                </>
              </div>
              <div className="grid gap-3 ">
                <>
                  <span className="text-xs">Groups</span>
                  <div className="flex flex-wrap gap-2">
                    {groups &&
                      groups.map((group) => (
                        <div className="flex justify-center w-fit font-medium items-center text-sm gap-2 px-4 py-2 text-shades-50 rounded-full bg-primary-500">
                          <>
                            {/* {group} */}
                            <button>
                              <Icon size="18px" name="x" />
                            </button>
                          </>
                        </div>
                      ))}
                    <button className="flex justify-center w-fit font-medium items-center text-sm gap-2 px-4 py-2 text-shades-50 rounded-full bg-primary-500">
                      <Icon size="18px" name="plus" />
                      Add Group
                    </button>
                  </div>
                </>
              </div>
            </div>

            <div className="grid row-span-1 py-2">
              <Label htmlFor="description">Event Description</Label>
              <Field className="h-28" name="description">
                {({ field }: FieldProps) => (
                  <textarea
                    placeholder=""
                    className="h-[100px] shadow-md rounded-lg px-4 py-2 border-neutral-50"
                    id="description"
                    {...field}
                  />
                )}
              </Field>
            </div>
            <button
              type="submit"
              className="grid row-span-1 py-2 mt-2 font-medium w-full text-white text-sm bg-primary-500 border rounded"
            >
              Save Changes
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
