import React from "react";
import { signupValidationSchema } from "../../../../formik/auth/ValidationSchema";
import { Field, Formik, Form } from "formik";
import CustomInput from "../../../../components/CustomInput";

const EditProfileModal = () => {
  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={signupValidationSchema}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {(formik) => (
        <Form>
          <div className="justify-center p-4">
            <div className="text-xl font-semibold">Owner</div>
            <div className="mt-2 font-semibold text-md">
              <Field name="owner" placeholder="Owner" component={CustomInput} />
            </div>
            <div className="mt-2 text-xl font-semibold">E-Mail</div>
            <div>
              <Field
                name="email"
                placeholder="Email Address"
                component={CustomInput}
              />
            </div>
            <div className="mt-2 text-xl font-semibold">Role</div>
            <div>
              <Field name="role" placeholder="Role" component={CustomInput} />
            </div>
            <div>
              <button className="w-full p-2 mt-4 text-center text-white bg-blue-1100">
                Save Changes
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditProfileModal;
