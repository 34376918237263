import { EventTicket, UpdateEventTicket } from "./../models/event-ticket.model";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { Event } from "../models/event.model";
import { Order } from "../models/order.model";
import { Organization } from "../models/organization.model";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface Events {
  data: Event[];
  skip: number;
  take: number;
  count: number;
}

interface Orders {
  data: Order[];
  skip: number;
  take: number;
  count: number;
}

interface Query {
  skip?: number;
  take?: number;
  search?: string;
}

interface Dashboard {
  overAllSales: number;
  pastEvents: number;
  recentEvents: Event[];
  thisWeekSales: number;
  totalEvents: number;
  upcomingEvents: number;
}

export const partnerApi = createApi({
  reducerPath: "partnerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/partner",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Events", "Event", "Dashboard", "Orders", "Profile"],
  endpoints: (builder) => ({
    // createSong: builder.mutation<Song, CreateSong>({
    //   query: (body) => ({
    //     url: "/",
    //     method: "POST",
    //     body,
    //   }),
    //   invalidatesTags: ["Songs"],
    // }),
    getPartnerOrders: builder.query<Orders, Query>({
      query: ({ take, skip, search }) => {
        return {
          url: "/orders",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["Orders"],
    }),
    getPartnerProfile: builder.query<Organization, unknown>({
      query: () => {
        return {
          url: "/profile",
        };
      },
      providesTags: ["Profile"],
    }),
    getPartnerEvents: builder.query<Events, Query>({
      query: ({ take, skip, search }) => {
        return {
          url: "/events",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["Events"],
    }),
    getPartnerDashboard: builder.query<Dashboard, {}>({
      query: () => {
        return {
          url: "/dashboard",
        };
      },
      providesTags: ["Dashboard"],
    }),
    getPartnerEvent: builder.query<Event, string>({
      query: (id) => ({
        url: `/events/${id}`,
      }),
      providesTags: ["Event"],
    }),
    updatePartnerEventTicket: builder.mutation<EventTicket, UpdateEventTicket>({
      query: ({ id, ...body }) => ({
        url: `/event-tickets/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Event", "Events"],
    }),
    // uploadSongImage: builder.mutation<Song, { id: string; file: File }>({
    //   query: ({ id, ...body }) => {
    //     const formData = new FormData();
    //     formData.append("file", body.file);
    //     return {
    //       url: `/${id}/upload`,
    //       method: "PATCH",
    //       body: formData,
    //     };
    //   },
    //   invalidatesTags: ["Songs", "Song"],
    // }),

    // deleteSong: builder.mutation<void, string>({
    //   query: (id) => ({
    //     url: `/${id}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["Songs"],
    // }),

    // removeSongPhoto: builder.mutation<void, string>({
    //   query: (id) => ({
    //     url: `/${id}/photo`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["Songs", "Song"],
    // }),
  }),
});

export const {
  useGetPartnerProfileQuery,
  useGetPartnerOrdersQuery,
  useGetPartnerEventsQuery,
  useGetPartnerEventQuery,
  useGetPartnerDashboardQuery,
  useUpdatePartnerEventTicketMutation,
} = partnerApi;
