import React from "react";

import Group225 from "../../assets/images/Group225.png";
import Group226 from "../../assets/images/Group226.png";
import Group227 from "../../assets/images/Group227.png";

const SalesReportPage = () => {
  return (
    <div className="flex">
      <div className="w-2/3">
        <div className="pt-4 text-4xl font-semibold tracking-wider text-lightBlue-500">
          SALES REPORT
        </div>
        <div className="flex justify-between mt-4 mr-8">
          <div className="flex flex-col items-center justify-center w-[250px] h-[180px] border-2 shadow-lg">
            <span className="text-5xl font-bold text-lightBlue-500">69</span>
            <span className="mt-5 text-lg">Events Created</span>
          </div>
          <div className="flex flex-col items-center justify-center w-[250px] h-[180px] border-2 shadow-lg">
            <span className="text-5xl font-bold text-lightBlue-500">200k</span>
            <span className="mt-5 text-lg">Highest Sales</span>
          </div>
          <div className="flex flex-col items-center justify-center w-[250px] h-[180px] border-2 shadow-lg">
            <span className="text-5xl font-bold text-lightBlue-500">100k</span>
            <span className="mt-5 text-lg">Average Sales</span>
          </div>
          <div className="flex flex-col items-center justify-center w-[250px] h-[180px] border-2 shadow-lg">
            <span className="text-5xl font-bold text-lightBlue-500">50k</span>
            <span className="mt-5 text-lg">Lowest Sales</span>
          </div>
        </div>
        <div className="mr-8">
          <div>
            <div className="my-3">
              <span className="text-2xl">Sales Chart</span>
            </div>
            <div className="flex flex-col items-center justify-center w-full h-[300px] border-2 shadow-lg">
              <img
                className="w-[300px] h-[184px]"
                src={Group225}
                alt="Group225"
              />
              <span className="mt-2 font-light text-center text-md">
                Hold on, creating the bars <br /> for you.
              </span>
            </div>
          </div>
          <div>
            <div className="my-3">
              <span className="text-2xl">Past Events</span>
            </div>
            <div className="flex flex-col items-center shadow-lg justify-center w-full h-[200px] border-2">
              <img
                className="h-[145px] w-[180px]"
                src={Group226}
                alt="Group226"
              />
              <span className="mt-2 text-xs font-light text-center">
                Creating today,to see the yesterday
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="w-1/3 h-full ">
        <div className="mb-5">
          <span className="text-2xl">Last Event</span>
        </div>
        <div className="border-2 shadow-lg h-[340px] w-full">
          <div className="flex flex-col mt-10 mb-4 text-center">
            <span className="text-5xl font-bold text-lightBlue-500">82k</span>
            <span className="pt-4 text-lg">Ticket Sales</span>
          </div>
          <div className="flex flex-col px-6">
            <span className="text-sm">Tickets Bought:</span>
            <span className="text-lg font-semibold">200</span>
          </div>
          <div className="flex flex-col px-6 my-4">
            <span className="text-sm">Tickets Type Most Bought:</span>
            <span className="text-lg font-semibold">General Admission</span>
          </div>
          <div className="flex flex-col px-6">
            <span className="text-sm">Expected Sales:</span>
            <span className="text-lg font-semibold">70,000</span>
          </div>
        </div>
        <div className="mt-4">
          <span className="text-2xl">Performers</span>
          <div className="flex flex-col items-center justify-center h-[410px] w-full mt-4 border-2 shadow-lg">
            <img
              className="w-[250px] h-[180px]"
              src={Group227}
              alt="Group227"
            />
            <span className="mx-20 mt-4 text-lg tracking-wider text-center">
              They are still creating the musical score while we listen with
              glee.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesReportPage;
