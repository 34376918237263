import React from "react";

const OverallSvg = () => {
  return (
    <svg
      width="132"
      height="128"
      viewBox="0 0 132 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_867_716)">
        <path
          d="M40.7623 93.8975H24.2277C23.8109 93.8975 23.4717 93.5606 23.4717 93.1467C23.4717 92.7327 23.8109 92.396 24.2277 92.396H40.7623C41.1791 92.396 41.5182 92.7327 41.5182 93.1467C41.5182 93.5606 41.1791 93.8975 40.7623 93.8975H40.7623Z"
          fill="#BBCAF3"
        />
        <path
          d="M11.2324 97.9165C11.2324 98.0514 11.3415 98.1597 11.4773 98.1597H53.5128C53.6485 98.1597 53.7576 98.0514 53.7576 97.9165C53.7576 97.7816 53.6485 97.6733 53.5128 97.6733H11.4773C11.3415 97.6733 11.2324 97.7816 11.2324 97.9165Z"
          fill="#202430"
        />
        <path
          d="M99.9205 48.9208C107.307 48.9208 113.295 42.9741 113.295 35.6384C113.295 28.3027 107.307 22.356 99.9205 22.356C92.5339 22.356 86.5459 28.3027 86.5459 35.6384C86.5459 42.9741 92.5339 48.9208 99.9205 48.9208Z"
          fill="#FECACA"
        />
        <path
          d="M0 114.701C0 114.836 0.109061 114.944 0.244871 114.944H107.713C107.849 114.944 107.958 114.836 107.958 114.701C107.958 114.566 107.849 114.458 107.713 114.458H0.244871C0.109061 114.458 0 114.566 0 114.701Z"
          fill="#202430"
        />
        <path
          d="M1.55472 98.2787L1.21094 97.9047L34.7635 67.4989L46.8519 72.8805L59.9056 50.649L70.6304 45.5151L80.5673 61.9623C85.9321 48.1693 99.3864 33.7386 115.508 19.0977L115.905 19.4166C100.784 33.7931 87.847 48.235 80.505 62.8441L71.3138 47.5862C70.8796 46.8654 69.9671 46.587 69.2003 46.9413L69.2003 46.9413C63.338 49.6503 58.4482 54.0721 55.1847 59.6154L47.0078 73.505L45.1413 72.674C38.8988 69.895 31.5909 71.0597 26.5376 75.6391L1.55472 98.2787Z"
          fill="#202430"
        />
        <path
          d="M116.265 21.8661L115.755 21.9012L114.933 20.1321L113.093 19.7608L113.021 19.2587L116.055 18.8306L116.265 21.8661Z"
          fill="#202430"
        />
        <path
          d="M63.9277 32.6912L65.7313 25.8054L72.7576 25.4697L74.8597 34.9617L63.9277 32.6912Z"
          fill="#FCA5A5"
        />
        <path
          opacity="0.1"
          d="M63.9277 32.6912L65.7313 25.8054L72.7576 25.4697L74.8597 34.9617L63.9277 32.6912Z"
          fill="#101218"
        />
        <path
          d="M68.5511 29.6586C73.2349 29.6586 77.0319 25.8878 77.0319 21.2363C77.0319 16.5848 73.2349 12.814 68.5511 12.814C63.8673 12.814 60.0703 16.5848 60.0703 21.2363C60.0703 25.8878 63.8673 29.6586 68.5511 29.6586Z"
          fill="#FCA5A5"
        />
        <path
          d="M73.2297 23.0192H73.2297C73.2297 23.0192 70.0079 16.6799 66.2332 17.0459C62.4584 17.4119 60.4697 17.857 60.4697 17.857C60.4697 17.857 59.3492 12.9197 64.6246 11.0205C64.6246 11.0205 57.9171 4.45411 65.903 1.233C73.8889 -1.98811 78.3575 2.12001 78.3575 2.12001C78.3575 2.12001 84.2688 7.98051 76.8238 12.3607C76.8238 12.3607 82.352 19.7455 75.6588 25.8322C75.6588 25.8322 77.1558 20.9067 75.5938 21.815C74.0318 22.7234 73.2297 23.0192 73.2297 23.0192V23.0192L73.2297 23.0192L73.2297 23.0192Z"
          fill="#202430"
        />
        <path
          d="M59.1934 67.5181L58.0652 68.5918L49.9155 85.9458L49.0234 114.651H62.7161L69.1392 100.436L73.467 114.513H85.7786L88.6327 88.958L80.9606 70.8847L59.1934 67.5181Z"
          fill="#202430"
        />
        <path
          d="M73.378 31.9912H64.2337L54.2871 37.3069L56.6066 47.5839C56.6066 47.5839 56.0713 53.7856 57.3202 58.3925C58.5692 62.9994 58.0339 69.0239 58.0339 69.0239L80.9609 70.8844L81.6746 49.976L81.3177 35.978L73.378 31.9912V31.9912Z"
          fill="#F4F5F7"
        />
        <path
          d="M55.8043 37.3955C55.8043 37.3955 50.2733 37.5727 49.3812 41.1165C48.4891 44.6603 47.7754 62.9108 47.7754 62.9108C47.7754 62.9108 48.3106 73.7403 54.2877 68.5914C60.2648 63.4424 59.7295 47.8497 59.7295 47.8497L55.8043 37.3955Z"
          fill="#F4F5F7"
        />
        <path
          d="M109.451 127.795H76.8565C75.7526 127.795 74.8545 126.904 74.8545 125.807V92.3442C74.8545 91.2478 75.7526 90.356 76.8565 90.356H109.451C110.555 90.356 111.453 91.2478 111.453 92.3442V125.807C111.453 126.904 110.555 127.795 109.451 127.795Z"
          fill="#F9FAFB"
        />
        <path
          d="M109.451 128H76.8562C75.6389 128 74.6484 127.017 74.6484 125.808V92.3444C74.6484 91.1355 75.6389 90.1519 76.8562 90.1519H109.451C110.668 90.1519 111.659 91.1355 111.659 92.3444V125.808C111.659 127.017 110.668 128 109.451 128ZM76.8562 90.5605C75.8657 90.5605 75.06 91.3608 75.06 92.3444V125.808C75.06 126.791 75.8657 127.591 76.8562 127.591H109.451C110.441 127.591 111.247 126.791 111.247 125.808V92.3444C111.247 91.3608 110.441 90.5605 109.451 90.5605H76.8562Z"
          fill="#202430"
        />
        <path
          d="M94.4398 119.005H91.7533C91.4262 119.005 91.1602 118.801 91.1602 118.55V107.064C91.1602 106.813 91.4262 106.609 91.7533 106.609H94.4398C94.7669 106.609 95.033 106.813 95.033 107.064V118.55C95.033 118.801 94.7669 119.005 94.4398 119.005Z"
          fill="#BBCAF3"
        />
        <path
          d="M85.5584 112.236C85.2941 112.236 85.0791 112.401 85.0791 112.603V118.537C85.0791 118.74 85.2941 118.905 85.5584 118.905H88.2448C88.5091 118.905 88.7241 118.74 88.7241 118.537V112.603C88.7241 112.401 88.5091 112.236 88.2448 112.236H85.5584Z"
          fill="#BBCAF3"
        />
        <path
          d="M100.635 119.005H97.9486C97.6215 119.005 97.3555 118.801 97.3555 118.55V99.7257C97.3555 99.475 97.6215 99.271 97.9486 99.271H100.635C100.962 99.271 101.228 99.475 101.228 99.7257V118.55C101.228 118.801 100.962 119.005 100.635 119.005Z"
          fill="#BBCAF3"
        />
        <path
          d="M42.8244 36.4903H39.7557C39.3821 36.4903 39.0781 36.2573 39.0781 35.9709V22.85C39.0781 22.5636 39.3821 22.3306 39.7557 22.3306H42.8244C43.1981 22.3306 43.502 22.5636 43.502 22.85V35.9709C43.502 36.2573 43.1981 36.4903 42.8244 36.4903V36.4903V36.4903Z"
          fill="#BBCAF3"
        />
        <path
          d="M32.6793 28.7583C32.3775 28.7583 32.1318 28.9466 32.1318 29.178V35.9565C32.1318 36.1879 32.3775 36.3762 32.6793 36.3762H35.748C36.0499 36.3762 36.2955 36.1879 36.2955 35.9565V29.178C36.2955 28.9466 36.0499 28.7583 35.748 28.7583H32.6793Z"
          fill="#BBCAF3"
        />
        <path
          d="M49.9016 36.4904H46.8328C46.4592 36.4904 46.1553 36.2574 46.1553 35.971V14.4681C46.1553 14.1817 46.4592 13.9487 46.8328 13.9487H49.9016C50.2752 13.9487 50.5791 14.1817 50.5791 14.4681V35.971C50.5791 36.2574 50.2752 36.4904 49.9016 36.4904V36.4904V36.4904Z"
          fill="#BBCAF3"
        />
        <path
          d="M88.1568 95.2249C89.7462 95.0738 90.8407 92.9407 90.6016 90.4596C90.5197 89.4671 90.1998 88.5086 89.6684 87.6641L89.3621 84.856L89.409 84.842L90.179 71.5273C90.3581 68.4315 89.1241 65.3894 86.7467 63.3791C85.3324 62.1833 83.8236 61.5778 82.8534 63.0888C81.2436 65.596 83.3288 75.9312 84.6982 81.8071L85.2291 88.0862C84.8684 89.0156 84.7372 90.0174 84.8464 91.0075C85.0851 93.4878 86.5673 95.376 88.1568 95.2249V95.2249Z"
          fill="#FCA5A5"
        />
        <path
          d="M79.3555 36.3323C79.3555 36.3323 83.9944 34.5604 86.1354 39.8761C88.2765 45.1918 90.5959 71.4159 90.5959 71.4159L79.7123 69.4668L79.3555 36.3323V36.3323V36.3323Z"
          fill="#F4F5F7"
        />
        <path
          d="M15.6577 33.7329H9.4113C8.99445 33.7329 8.65527 33.3961 8.65527 32.9821C8.65527 32.5681 8.99446 32.2314 9.4113 32.2314H15.6577C16.0745 32.2314 16.4136 32.5681 16.4136 32.9821C16.4136 33.3961 16.0745 33.7329 15.6577 33.7329Z"
          fill="#F4F5F7"
        />
        <path
          d="M15.6577 23.0908H9.4113C8.99445 23.0908 8.65527 22.754 8.65527 22.34C8.65527 21.926 8.99446 21.5894 9.4113 21.5894H15.6577C16.0745 21.5894 16.4136 21.926 16.4136 22.34C16.4136 22.754 16.0745 23.0908 15.6577 23.0908Z"
          fill="#F4F5F7"
        />
        <path
          d="M15.6577 15.416H9.4113C8.99445 15.416 8.65527 15.0792 8.65527 14.6652C8.65527 14.2512 8.99446 13.9146 9.4113 13.9146H15.6577C16.0745 13.9146 16.4136 14.2512 16.4136 14.6652C16.4136 15.0792 16.0745 15.416 15.6577 15.416Z"
          fill="#F4F5F7"
        />
        <path
          d="M8.4043 36.5278C8.4043 36.6627 8.51336 36.771 8.64917 36.771H50.6846C50.8204 36.771 50.9295 36.6627 50.9295 36.5278C50.9295 36.393 50.8204 36.2847 50.6846 36.2847H8.64917C8.51336 36.2847 8.4043 36.393 8.4043 36.5278Z"
          fill="#202430"
        />
        <path
          d="M89.4746 99.4658C89.4746 99.6007 89.5837 99.709 89.7195 99.709H131.755C131.891 99.709 132 99.6007 132 99.4658C132 99.331 131.891 99.2227 131.755 99.2227H89.7195C89.5837 99.2227 89.4746 99.331 89.4746 99.4658Z"
          fill="#202430"
        />
        <path
          d="M77.1289 119.287C77.1289 119.422 77.238 119.53 77.3738 119.53H119.409C119.545 119.53 119.654 119.422 119.654 119.287C119.654 119.152 119.545 119.044 119.409 119.044H77.3738C77.238 119.044 77.1289 119.152 77.1289 119.287Z"
          fill="#202430"
        />
        <path
          d="M48.8624 35.298C47.2729 35.4492 46.1784 37.5822 46.4176 40.0634C46.4994 41.0558 46.8194 42.0144 47.3507 42.8588L47.657 45.6669L47.6101 45.6809L46.8401 58.9956C46.6611 62.0914 47.8951 65.1335 50.2725 67.1438C51.6868 68.3397 53.1955 68.9452 54.1657 67.4342C55.7756 64.927 53.6903 54.5918 52.321 48.7158L51.7901 42.4368C52.1507 41.5073 52.2819 40.5056 52.1727 39.5154C51.934 37.0351 50.4518 35.1469 48.8624 35.298H48.8624V35.298Z"
          fill="#FCA5A5"
        />
      </g>
      <defs>
        <clipPath id="clip0_867_716">
          <rect width="132" height="128" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OverallSvg;
