import React from "react";
import BannerProfile from "../../assets/images/Banner.png";
import ProfilePic from "../../assets/images/ProfilePic.png";
import ProfileIcon from "../../assets/images/ProfileIcon.png";
import PasswordIcon from "../../assets/images/PasswordIcon.png";
import MembersIcon from "../../assets/images/MembersIcon.png";
import EwalletIcon from "../../assets/images/EwalletIcon.png";
import { Link, Outlet } from "react-router-dom";

const ProfilePage = () => {
  return (
    <div>
      <div>
        <div className="relative">
          <img src={BannerProfile} alt="Banner" className="w-full" />
          <div className="absolute ml-4 top-36">
            <img src={ProfilePic} alt="Profile" className="" />
          </div>
        </div>
      </div>
      <div className="p-4 bg-[#E8EDFB]">
        <div className="flex flex-grow">
          <div className="bg-blue-1100 w-[200px] rounded-md mr-4 mt-20">
            <div className="w-[200px] h-[636px] gap-8 px-[8px] py-[32px] flex flex-col items-center text-white">
              <Link to="profilepage">
                <div className="flex items-center">
                  <div className="mr-2">
                    <img src={ProfileIcon} alt="ProfileIcon" />
                  </div>
                  <div>Profile</div>
                </div>
              </Link>
              {/* <Link to="member">
                <div className="flex items-center">
                  <div className="mr-2">
                    <img src={MembersIcon} alt="MembersIcon" />
                  </div>
                  <div>Member</div>
                </div>
              </Link>
              <Link to="ewallet">
                <div className="flex items-center">
                  <div className="mr-2">
                    <img src={EwalletIcon} alt="EwalletIcon" />
                  </div>
                  <div>E-Wallet</div>
                </div>
              </Link> */}
            </div>
          </div>
          <div className="flex-1">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
