
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authReducer from "../features/Authentication/authSlice";
import { authApi } from "../services/auth.api";
import { partnerApi } from "../services/partner.api";



export const store = configureStore({
  reducer: {
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    [partnerApi.reducerPath]: partnerApi.reducer,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      partnerApi.middleware,

    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
