import React from "react";
import Header from "../../components/CreateEventPage/Header";
import Banner from "../../components/CreateEventPage/Banner";
import EventDetailsForm from "../../components/CreateEventPage/EventDetailsForm";
import TicketQuantityForm from "../../components/CreateEventPage/TicketQuantityForm";
import TicketTypeForm from "../../components/CreateEventPage/TicketTypeForm";

function CreateEventForm() {
  return (
    <div className="bg-lightGray-300 w-full h-full">
      <Header />
      <Banner />
      <EventDetailsForm />
      <TicketQuantityForm />
      <TicketTypeForm />
    </div>
  );
}

export default CreateEventForm;
