import React from "react";
import Header from "../../components/CreateEventPage/Header";
import Banner from "../../components/CreateEventPage/Banner";
import EventDetailsForm from "../../components/CreateEventPage/EventDetailsForm";
import TicketQuantityForm from "../../components/CreateEventPage/TicketQuantityForm";
import TicketTypeForm from "../../components/CreateEventPage/TicketTypeForm";
import CreateEventForm from "./CreateEventForm";

const CreateEventPage = () => {
  return <div className="bg-lightGray-300 w-full h-full">
    <CreateEventForm /> 
  </div>;
};

export default CreateEventPage;
