import { ErrorMessage } from "formik";
import React from "react";

interface ComponentProps {
  divClass?: string;
  textArea?: boolean;
}

type TExtendedProps = ComponentProps & React.HTMLProps<HTMLInputElement>;

export const Input: React.FC<TExtendedProps> = ({
  divClass,
  textArea,
  ...props
}) => {
  return (
    <div className={`${divClass && divClass} flex-col flex`}>
      {textArea ? (
        <textarea
          className={`h-[100px] shadow-md rounded-lg px-4 py-2 col-span-2 border-neutral-50`}
        />
      ) : (
        <input
          {...props}
          className={`shadow-md rounded-lg px-4 py-2 col-span-2 border-neutral-50`}
        />
      )}

      {props.name && (
        <ErrorMessage name={props.name || ""}>
          {(message) => <span className="text-red-500">{message}</span>}
        </ErrorMessage>
      )}
    </div>
  );
};
