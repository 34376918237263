import { useState } from "react";
import EditIcon from "../../../assets/images/EditIcons.png";
import Generalinfo from "./ProfilePage/Generalinfo";
import Password from "./ProfilePage/Password";
import EditProfileModal from "./ProfilePage/EditProfileModal";
import { Modal } from "../../../components/Modal";
import ChangePassword from "./ProfilePage/ChangePassword";
import { useGetPartnerProfileQuery } from "../../../services/partner.api";
import dayjs from "dayjs";

const ProfileNavPage = () => {
  const [generalInfo, setGeneralInfo] = useState(true);
  const [password, setPassword] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const { data } = useGetPartnerProfileQuery({});
  console.log("data", data);
  return (
    <div className="w-full h-full p-6 bg-white ">
      <div className="flex justify-between">
        <div>
          <div className="text-xl font-bold">{data?.organization.name}</div>
          <div className="text-sm">
            Joined since{" "}
            {dayjs(data?.organization.createdAt).format("MMMM YYYY")}
          </div>
        </div>
        {/* <div>
          <div className="text-xl font-bold">69</div>
          <div>Production Members</div>
        </div> */}
        {/* <div className="flex items-center px-4 py-2 text-sm text-white rounded-md bg-blue-1100">
          <div className="mr-2">
            <img src={EditIcon} alt="EditIcon" />
          </div>
          <div>
            {generalInfo ? (
              <button
                onClick={() => {
                  setEditProfile(true);
                }}
              >
                Edit Profile
              </button>
            ) : (
              <button
                onClick={() => {
                  setChangePassword(true);
                }}
              >
                Change Password
              </button>
            )}
          </div>
        </div> */}
      </div>
      <div className="flex py-4 text-sm ">
        <div className="mr-2 text-center">
          <button
            onClick={() => {
              setGeneralInfo(true);
              setPassword(false);
            }}
            className={
              generalInfo ? "border-b-4 font-bold border-blue-1100 mr-2" : ""
            }
          >
            General Information
          </button>
        </div>
        {editProfile && (
          <Modal
            title={"General Informatuion"}
            open={editProfile}
            setOpen={setEditProfile}
          >
            <EditProfileModal />
          </Modal>
        )}
        {/* <div className="bg-b-1100">
          <button
            onClick={() => {
              setPassword(true);
              setGeneralInfo(false);
            }}
            className={
              password ? "border-b-4 font-bold border-blue-1100 ml-2" : ""
            }
          >
            Password
          </button>
        </div> */}
        {changePassword && (
          <Modal
            title={"Change Password"}
            // bgColor="bg-white"
            // textTitleColor="text-black"
            open={changePassword}
            setOpen={setChangePassword}
          >
            <ChangePassword />
          </Modal>
        )}
      </div>
      <div>
        {password ? (
          <Password />
        ) : (
          <Generalinfo
            name={`${data?.firstName} ${data?.lastName}`}
            email={data?.email || ""}
          />
        )}
      </div>
      {/* <div>{editProfile && <EditProfileModal />}</div>
      <div>{changePassword && <ChangePassword />}</div> */}
    </div>
  );
};

export default ProfileNavPage;
