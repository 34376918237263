import React from "react";

const UpcomingEventSvg = () => {
  return (
    <svg
      width="64"
      height="70"
      viewBox="0 0 64 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <path
          d="M3.62383 0.855218C11.9605 6.97674 33.9467 22.9517 61.8702 20.4148C62.1419 20.3828 62.4171 20.4098 62.6774 20.4938C62.938 20.5774 63.1772 20.7164 63.3792 20.9008C63.5811 21.0852 63.7409 21.3112 63.8481 21.5627C63.955 21.8145 64.0067 22.0861 63.9993 22.3597V66.604C63.9853 67.1898 63.7696 67.7526 63.3891 68.1978C63.0084 68.6431 62.4859 68.9436 61.9098 69.0487C53.5141 70.4621 18.5964 69.7641 0.58275 51.7348C0.396416 51.548 0.248957 51.3259 0.148915 51.0817C0.048873 50.8373 -0.00161985 50.5758 3.9613e-05 50.3117V2.96084C3.9613e-05 1.08541 2.19693 -0.192377 3.62383 0.855218Z"
          fill="#8EA7EC"
        />
        <path
          d="M17.1915 45.6212C12.5394 43.9807 8.76788 38.8798 8.76788 34.2274C8.76788 29.5752 12.5394 27.1336 17.1915 28.7741V23.3671C9.67585 20.717 3.58301 24.6615 3.58301 32.1772C3.58301 39.6929 9.67585 47.9338 17.1915 50.5837C24.3896 53.122 30.2785 49.6084 30.7636 42.7046L25.5823 40.8775C25.216 45.0627 21.6005 47.1759 17.1915 45.6212Z"
          fill="#F9FAFB"
        />
        <path
          d="M18.9199 22.248V27.6551C23.5721 29.2953 27.3436 34.3965 27.3436 39.0487C27.3436 39.2919 27.3308 39.5278 27.3106 39.7585L32.4922 41.5855C32.5135 41.2809 32.5282 40.9722 32.5282 40.6548C32.5282 33.1391 26.4356 24.8982 18.9199 22.248Z"
          fill="#1D4ED8"
        />
        <path
          d="M8.76788 34.2272L3.58301 32.3989"
          stroke="#F9FAFB"
          strokeWidth="2.42819"
          strokeMiterlimit="10"
        />
        <path
          d="M39.0146 35.0005L48.6357 36.8287"
          stroke="#F9FAFB"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.4"
          d="M39.0146 50.0747L48.6357 51.903"
          stroke="#F9FAFB"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M39.0146 42.6909L52.837 45.3176"
          stroke="#F9FAFB"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export default UpcomingEventSvg;
