import { useState } from "react";
import EyeIcon from "../../../../assets/images/EyeIcons.png";

const Password = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  return (
    <div className="mt-4">
      <div className="text-xs">Current Password</div>
      <div className="flex mt-2">
        <input
          type={passwordShown ? "text" : "password"}
          className="relative w-64 p-2 border-2"
        />
        <button
          onClick={togglePassword}
          className="absolute left-[645px] top-[469px]"
        >
          <img src={EyeIcon} alt="Icon" />
        </button>
      </div>
    </div>
  );
};

export default Password;
