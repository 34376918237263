import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const SignInFacebook = () => {
  const google = () => {
    window.open(`${process.env.REACT_APP_BASE_URL}/auth/facebook`, "_self");
  };
  return (
    <button type="button" onClick={google} className="text-white bg-[#4267B2] px-2 py-1 rounded-sm">
      <FontAwesomeIcon icon={faFacebook} className="mr-2" />
      Facebook
    </button>
  );
};

export default SignInFacebook;
