import React, { useState } from "react";
import { Icon } from "ts-react-feather-icons";

interface AccordionProps {
  children: React.ReactNode;
  label: string;
}

const Accordion: React.FC<AccordionProps> = ({ children, label }) => {
  const [expand, setExpand] = useState(false);

  const toggleExpand = () => setExpand((prevExpand) => !prevExpand);

  return (
    <>
      <div className="border-t-0 border-b border-neutral-500">
        <button
          className="flex w-full h-[60px] py-4 px-5 justify-between items-center text-white bg-primary-400"
          type="button"
          onClick={toggleExpand}
        >
          <div className="flex items-center gap-2">
            <span className="mr-2 text-base font-semibold">{label}</span>
          </div>
          <div
            className={`flex gap-2 justify-end ${
              expand ? "rotate-180" : "rotate-180"
            }`}
          >
            <span>
              <Icon name={expand ? "chevron-up" : "chevron-down"} />
            </span>
          </div>
        </button>
        {expand && <div className="overflow-y-scroll h-96">{children}</div>}
      </div>
    </>
  );
};

export default Accordion;
