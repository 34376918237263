import { useState } from "react";
import AddIcon from "../../../assets/images/AddIcon.png";
import { Modal } from "../../../components/Modal";
import DropdownIcon from "../../../assets/images/DropdownIcon.png";
import AddMemberModal from "./MemberPage/AddMemberModal";

function MembersNavPage() {
  const [openRole, setOpen] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [newMember, setNewmember] = useState(false);
  return (
    <div className="w-full h-full p-6 bg-[#F9FAFB] ">
      <div className="flex justify-between">
        <div>
          <div className="text-sm ">Production Members</div>
          <div className="text-xl font-bold">69</div>
        </div>
        <div className="flex items-center justify-between text-sm">
          <div className="mr-4">Filter by</div>
          <div className="relative mr-4 font-medium">
            <button
              onClick={() => {
                setOpen(!openRole);
              }}
              className=" flex items-center justify-center  bg-[#F4F5F7] px-4 py-1"
            >
              Role
              <img src={DropdownIcon} alt="Dropdown" className="w-3 ml-1" />
            </button>
            {openRole && (
              <div className="absolute flex flex-col items-start bg-white">
                <button>User</button>
                <button>Operator</button>
                <button>Admin</button>
              </div>
            )}
          </div>
          <div className="relative mr-4 font-medium">
            <button
              onClick={() => {
                setOpenStatus(!openStatus);
              }}
              className=" flex items-center justify-center  bg-[#F4F5F7] px-4 py-1"
            >
              Status
              <img src={DropdownIcon} alt="Dropdown" className="w-3 ml-1" />
            </button>
            {openStatus && (
              <div className="absolute items-start p-2 bg-white">
                <button>Active</button>
                <button>Inactive</button>
              </div>
            )}
          </div>
        </div>
        <div className="relative">
          <button
            onClick={() => {
              setNewmember(!newMember);
            }}
            className="flex items-center justify-center px-4 py-4 text-white rounded-md bg-blue-1100"
          >
            <img src={AddIcon} alt="AddIcon" className="mr-2 " />
            Add Members
          </button>
          {newMember && (
            <Modal
              title={"Add New User"}
              open={newMember}
              setOpen={setNewmember}
            >
              <AddMemberModal />
            </Modal>
          )}
        </div>
      </div>
      <div>
        <div>
          <table className="w-full mt-16">
            <thead>
              <tr>
                <th>Name</th>
                <th>E-mail</th>
                <th>Role</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default MembersNavPage;
