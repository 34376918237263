import { ErrorMessage, Field, FieldProps } from "formik";
import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface ComponentProps {
  name?: string;
  minDate?: Date;
  placeholder?: string;
  className?: string;
}

export const DatePicker: React.FC<ComponentProps> = ({
  name = "dateTime",
  minDate,
  placeholder,
  className,
}) => {
  return (
    <div className={`${className && className}`}>
      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <ReactDatePicker
            {...field}
            selected={field.value}
            onChange={(date: Date) => form.setFieldValue(field.name, date)}
            dateFormat="MM/dd/yyyy h:mm aa"
            showTimeSelect
            className={`w-full px-4 py-2 border z-100 shadow-md rounded-lg col-span-2 border-neutral-50`}
            placeholderText={placeholder}
            popperClassName="z-100"
            minDate={minDate}
          />
        )}
      </Field>
      <ErrorMessage name={name}>
        {(message) => <span className="text-red-500">{message}</span>}
      </ErrorMessage>
    </div>
  );
};

export default DatePicker;
