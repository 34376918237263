import React from "react";

interface ComponentProps {
  name: string;
  email:string;
}
const Generalinfo: React.FC<ComponentProps> = ({name, email}) => {
  return (
    <div>
      <div>
        <div className="py-3">
          <div className="text-xs">Name</div>
          <div className="text-xl font-bold">{name}</div>
        </div>
        <div className="py-3">
          <div className="text-xs">E-mail</div>
          <div className="text-xl font-bold">{email}</div>
        </div>
        {/* <div className="py-3">
          <div className="text-xs">Role</div>
          <div className="text-xl font-bold">Admin</div>
        </div>
        <div className="py-3">
          <div className="text-xs">Membership</div>
          <div className="flex">
            <div className="mr-2 text-xl font-bold">Standard</div>
            <div className="mt-2 text-xs">since July 17, 2022</div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Generalinfo;
