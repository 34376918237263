import React from "react";

const PastEventSvg = () => {
  return (
    <svg
      width="76"
      height="68"
      viewBox="0 0 76 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M74.1659 31.4875L22.0937 1.42362C21.1677 0.889008 19.9836 1.2063 19.4488 2.13249L1.12658 33.8675C0.591852 34.7937 0.909272 35.9777 1.83525 36.5123L53.9074 66.5762C54.8334 67.1108 56.0175 66.7937 56.5522 65.8675L74.8744 34.1325C75.4092 33.2063 75.0919 32.0221 74.1659 31.4875Z"
        fill="#1D4ED8"
      />
      <path
        d="M13.2338 21.4089C13.2338 21.4089 18.3807 23.0915 18.5476 26.1636C18.7146 29.2354 17.7497 34.2677 21.3626 35.6289C24.9754 36.9899 32.8981 26.9443 36.1409 28.9461C39.3838 30.9476 34.7133 37.8254 38.2473 39.9952C41.7814 42.1648 43.1538 37.477 45.4858 38.9732C48.3147 40.7884 43.0706 49.119 46.7986 51.4008C50.5267 53.6825 56.6928 42.1062 62.3055 45.6054"
        stroke="#F9FAFB"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M22.499 8.74023L31.6604 14.0296"
        stroke="#F9FAFB"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        opacity="0.4"
        d="M20.0303 13.0156L38.9629 23.9464"
        stroke="#F9FAFB"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default PastEventSvg;
