import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom';

interface ProtectedRouteProps {
  isAllowed: boolean;
  waitForAuth?: boolean;
  redirectPath?: string;
  children?: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isAllowed,
  redirectPath = "/",
  children,
  waitForAuth = false,
}): any => {
  const location = useLocation();

  if (!isAllowed) {
    if (waitForAuth) {
      return null;
    } else {
      return <Navigate to={redirectPath} replace state={{ from: location }} />;
    }
  }
  return children ? children : <Outlet />;
};

export default ProtectedRoute