import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Loading from "../../../components/Loading";
import { useRefreshMutation } from "../../../services/auth.api";
import { authSelect, logout, setUser } from "../authSlice";

interface ComponentProps {
  children: React.ReactNode;
}
const AuthContainer: React.FC<ComponentProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [refresh, { data, isSuccess, isLoading, isError }] =
    useRefreshMutation();
  const { user } = useAppSelector(authSelect);
  React.useEffect(() => {
    if (localStorage.getItem("refreshToken")) refresh({});
  }, [refresh]);

  React.useEffect(() => {
    if (isError) {
      localStorage.removeItem("refreshToken");
    }
  }, [isError]);

  React.useEffect(() => {
    if (!user?.organization) {
      dispatch(logout());
    }
  }, [user, dispatch]);

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      dispatch(
        setUser({
          refreshToken: data.refreshToken,
          accessToken: data.accessToken,
        })
      );
      localStorage.setItem("refreshToken", data.refreshToken);
    }
  }, [isSuccess, isLoading, data, dispatch]);
  if (isLoading) return <Loading />;
  return <>{children}</>;
};

export default AuthContainer;
