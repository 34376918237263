import React from "react";

function Header() {
  return (
    <div>
      <h1 className="text-5xl  font-Clash text-lightBlue-500 mt-3 ">
        CREATE EVENT REQUEST
      </h1>
    </div>
  );
}

export default Header;
