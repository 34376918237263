import React from "react";

import DropdownIcon from "../../assets/images/Dropdown.png";
import SearchIcons from "../../assets/images/SearchIcons.png";

interface CustomSearchBar {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CustomSearchBar: React.FC<CustomSearchBar> = ({
  open,
  setOpen,
}) => {
  return (
    <div className="flex w-full p-2 border rounded-md verflow-hidden">
      <div className="w-15">
        <button
          onClick={() => {
            setOpen(!open);
          }}
          className="relative flex items-center justify-center px-5 py-3 text-white rounded-l-md bg-blue-1100"
        >
          All
          <img
            src={DropdownIcon}
            alt="Dropdown"
            className="w-3 ml-1 text-white"
          />
        </button>
        {open && (
          <div className="absolute w-64 py-4 bg-white rounded-b-md">
            <div className="px-4 mb-2 font-semibold text-blue-1100">Filter</div>
            <div className="flex flex-col justify-between px-6 text-sm">
              <div className="flex mb-2">
                <div className="mr-4">
                  <input type="radio" name="radio" className="mr-2" />
                  <label>Event Name</label>
                </div>
                <div>
                  <input type="radio" name="radio" className="mr-2" />
                  <label>Venue</label>
                </div>
              </div>
              <div className="mb-2">
                <input type="radio" name="radio" className="mr-2" />
                <label>Event Date</label>
              </div>
              <div className="mb-2">
                <input type="radio" name="radio" className="mr-2" />
                <label>Performer</label>
              </div>
              <div className="mb-2">
                <input type="radio" name="radio" className="mr-2" />
                <label>Group</label>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="relative w-full">
        <input
          className="absolute w-full px-4 py-3 rounded-r-md"
          placeholder="Search Event"
        />
        <button className="absolute w-44 left-[1010px] top-[12px]">
          <img src={SearchIcons} alt="Icon" />
        </button>
      </div>
    </div>
  );
};
