import dayjs from "dayjs";
import React from "react";
import { Event } from "../../../models/event.model";
import KBar from "../assets/Kendricks 1.png";

interface ComponentProps {
  recentEvents: Event[];
}
const RecentEventList: React.FC<ComponentProps> = ({ recentEvents }) => {
  // const data = [
  //   {
  //     title: "Sabado Night's Manifest",
  //     venue: "Kendrick's Sports Bar",
  //     price: "15,000",
  //     venuePhoto: KBar,
  //   },
  //   {
  //     title: "Sabado Night's Manifest",
  //     venue: "Kendrick's Sports Bar",
  //     price: "15,000",
  //     venuePhoto: KBar,
  //   },
  //   {
  //     title: "Sabado Night's Manifest",
  //     venue: "Kendrick's Sports Bar",
  //     price: "15,000",
  //     venuePhoto: KBar,
  //   },
  // ];
  return (
    <>
      <h2 className="text-3xl font-[500] text-blue-1500 my-3">
        Recent Event List
      </h2>
      {recentEvents.map((data, index) => (
        <>
          <div className="flex justify-between">
            <div>
              <h3 className="text-lg font-[500]  leading-tight">{data.name}</h3>
              <p className="text-sm leading-tight">{data.venue?.name || ""}</p>
              <span className="text-xl font-[500] leading-tight">
                {dayjs(data?.dateTime).format("MMM DD, YYYY hh:mm A")}
              </span>
            </div>
            <img
            className="h-[60px] w-[60px]"
              src={
                data.image
                  ? process.env.REACT_APP_IMAGE_BASE_URL + data.image.fileName
                  : ""
              }
              alt="barPage"
            />
          </div>
          {recentEvents.length - 1 !== index && (
            <div className="my-2 border border-gray-400" />
          )}
        </>
      ))}
    </>
  );
};

export default RecentEventList;
