import { useState } from "react";
import xenditlogo from "../../../assets/images/xendit_logo.png";

function EwalletNavPage() {
  const [openRequest, setOpenRequest] = useState(false);
  return (
    <div className="flex w-full h-full p-8 bg-white ">
      <div className="flex flex-col w-1/2 p-2 ">
        <div className="text-xl font-bold">Wallet</div>
        <div className="flex flex-col justify-between p-4 mt-2 text-white rounded-md h-44 bg-blue-1100">
          <div className="flex justify-between ">
            <div className="text-xl ">TN WALLET</div>
            <div className="bg-white">
              <img src={xenditlogo} alt="logo" />
            </div>
          </div>
          <div>
            <div className="text-md">P99,000.00</div>
            <div>Balance</div>
          </div>
        </div>
        <div className="p-4 mt-2 text-center text-white rounded-md bg-blue-1100">
          <button
            onClick={() => {
              setOpenRequest(!openRequest);
            }}
          >
            Request
          </button>
        </div>
      </div>
      {openRequest && (
        <div className="w-1/2 p-2 ">
          <div className="text-xl font-bold">Withdrawal Request</div>
          <div className="bg-[#E3E5EC] mt-2 p-3 rounded-md h-44">
            <div className="text-sm">Amount</div>
            <div>
              <input type="text" className="p-4 mt-2 rounded-md w-[480px]" />
            </div>
            <div className="p-2 mt-8 text-center text-white rounded-md bg-blue-1100">
              <button>Withdrawal Request</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EwalletNavPage;
