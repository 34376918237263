import React from "react";

function EventDetailsForm() {
  return (
    <div>
      <div>
        <h1 className="my-4  font-Clash text-xl">Event Details</h1>
      </div>
      <div className="grid grid-rows-3 grid-flow-col gap-6 w-full h-48 ">
        <input
          placeholder="Event Name"
          className=" h-12 shadow-md pl-4 rounded-sm font-semibold"
        />
        <input
          placeholder="Venue"
          className=" h-12 shadow-md pl-4 rounded-sm font-semibold"
        />
        <input
          placeholder="Date and Time"
          className=" h-12 shadow-md pl-4 rounded-sm font-semibold"
        />
        <input
          placeholder="Performers"
          className=" shadow-md pl-4 rounded-sm font-semibold"
        />
        <textarea
          placeholder="Descriptions"
          className=" shadow-md pl-4 rounded-sm h-24 font-semibold pt-2"
        />
      </div>
    </div>
  );
}

export default EventDetailsForm;
