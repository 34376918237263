import React from "react";

interface ComponentProps {
  children: JSX.Element | JSX.Element[];
}
const Body: React.FC<ComponentProps> = ({ children }) => {
  return <div className="flex flex-col flex-grow overflow-y-scroll bg-white">{children}</div>;
};

export default Body;
