import React, { useState } from "react";
import { Icon } from "ts-react-feather-icons";
import { Modal } from "../../components/Modal";
import { EventTicket } from "../../models/event-ticket.model";
import { EditTicketForm } from "./ActionsModalForm/EditTicketForm";

interface ComponentProps {
  eventTicket: EventTicket;
}

const TicketsTable: React.FC<ComponentProps> = ({ eventTicket }) => {
  const [open, setOpen] = useState(false);
  const [publish, setPublish] = useState(false);

  return (
    <>
      <div className="flex items-center justify-between py-3 bg-primary-50">
        <div>
          <span className="px-6 py-4 text-xl font-medium">
            {`${
              eventTicket?.tickets
                ?.filter((t) => t.eventTicketId === eventTicket?.id)
                .filter((a) => a.status === "available")?.length
            } Ticket(s) Available`}
          </span>
          <span className="py-4">{`P${eventTicket.price}.00`}</span>
        </div>
{/* 
        <div className="flex items-center">
          {!publish && (
            <button
              className="flex gap-2 font-medium justify-center items-center h-[43px] px-4 py-2 text-shades-50 rounded-md bg-primary-500"
              onClick={() => {
                setOpen(true);
              }}
            >
              <Icon color="#FFFFFF" size="18px" name="edit" /> Edit Ticket Group
            </button>
          )}
          <button
            onClick={() => setPublish((prev) => !prev)}
            className="flex items-center gap-2 px-6 py-4 text-xl font-medium"
          >
            <Icon size="18px" name={publish ? "toggle-right" : "toggle-left"} />

            {publish ? "Unpublish" : "Publish"}
          </button>
        </div> */}
      </div>
      <table className="min-w-full bg-primary-50">
        <thead>
          <tr>
            <th className="px-6 py-4 text-xl font-medium text-left">
              Ticket ID
            </th>
            <th className="px-6 py-4 text-xl font-medium text-left">Section</th>
            <th className="px-6 py-4 text-xl font-medium text-left">Row</th>
            <th className="px-6 py-4 text-xl font-medium text-left">Seats</th>
            <th className="px-6 py-4 text-xl font-medium text-left">Status</th>
          </tr>
        </thead>
        <tbody>
          {eventTicket.tickets?.map((t) => (
            <>
              <tr
                key={t.id}
                className="transition duration-300 ease-in-out bg-primary-50 hover:bg-shades-50"
              >
                <td className="px-6 py-4 text-base whitespace-nowrap">
                  {t.id}
                </td>
                <td className="px-6 py-4 text-base whitespace-nowrap">
                  {t.section}
                </td>
                <td className="px-6 py-4 text-base whitespace-nowrap">
                  {t.row}
                </td>
                <td className="px-6 py-4 text-base whitespace-nowrap">
                  {t.seat}
                </td>
                <td className="px-6 py-4 text-base whitespace-nowrap">
                  {t.status}
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
      {open && (
        <Modal open={open} setOpen={setOpen} title="Edit Ticket Group" size="s">
          <EditTicketForm eventTicket={eventTicket} />
        </Modal>
      )}
    </>
  );
};

export default TicketsTable;
