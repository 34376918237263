import React from "react";
import UploadBannerIcon from "../../assets/images/Vector.png";

function Banner() {
  return (
    <div className="bg-white w-full h-48 rounded-2xl flex flex-col justify-center items-center mt-6 shadow-md ">
      <button className=" w-16 h-16 rounded-full text-white ">
        <img src={UploadBannerIcon} alt="logo " className="" />
      </button>
      <h1 className="mt-2   text-lightBlue-500 font-semibold text-2xl">
        Upload Banner
      </h1>
    </div>
  );
}

export default Banner;
