import React from "react";

interface ComponentProps {
  className: string;
  children?: JSX.Element | JSX.Element[];
}
const Sidebar: React.FC<ComponentProps> = ({ children, className }) => {
  return <div className={`p-2 lg:p-4 ${className}`}>{children}</div>;
};

export default Sidebar;
