import React from "react";
import UpcomingEventSvg from "../assets/UpcomingEventSvg";

interface ComponentProps {
  upcomingEvents: number;
}
const UpcomingEvents: React.FC<ComponentProps> = ({ upcomingEvents }) => {
  return (
    <>
      <div className="flex-1">
        <h2 className="text-4xl font-[600] text-blue-1100">{upcomingEvents}</h2>
        <p className="text-xl font-[500]">Upcoming Events</p>
      </div>
      <UpcomingEventSvg />
    </>
  );
};

export default UpcomingEvents;
