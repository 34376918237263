import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Accordion from "../../components/Common/Accordion";
import { Icon } from "ts-react-feather-icons";
import { useGetPartnerEventQuery } from "../../services/partner.api";
import dayjs from "dayjs";
import { ActionsModal } from "./ActionsModalForm";
import TicketsTable from "./TicketsTable";
import { EventTicket } from "../../models/event-ticket.model";

const SelectedEventPage: React.FC = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetPartnerEventQuery(id || "");
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [actionModalType, setActionModalType] = useState("");

  const availableTickets = data?.eventTickets?.map(
    (t) => t.tickets.filter((t) => t.status === "available").length
  );

  let numOfAvailableTix = 0;

  if (availableTickets) {
    for (let i = 0; i < availableTickets?.length; i++) {
      numOfAvailableTix = availableTickets[i] + numOfAvailableTix;
    }
  }

  if (!data) {
    return <h2>Event not found</h2>;
  }

  return (
    <div>
      <div className="flex flex-row justify-between items-center py-4 px-8 gap-5 h-[92px] bg-neutral-300">
        <div className="flex flex-col">
          <span className="text-xs">Available Tickets</span>
          <span className="text-2xl font-medium">{numOfAvailableTix}</span>
        </div>
        <div className="flex flex-col">
          <span className="text-xs">Event</span>
          <span className="text-2xl font-medium">{data.name}</span>
        </div>
        <div className="flex flex-col">
          <span className="text-xs">Date and Time</span>
          <span className="text-2xl font-medium">
            {dayjs(data?.dateTime).format("MM/DD/YYYY hh:mm")}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-xs">Venue</span>
          <span className="text-2xl font-medium">{data?.venue.name}</span>
        </div>
        <div className="flex flex-row" onClick={() => setOpen(!open)}>
          <button
            className="flex gap-2 text-start"
            onClick={() => {
              setOpen((prev) => !prev);
              setOpenModal((prev) => !prev);
              setActionModalType("Event Details");
            }}
          >
            <Icon color="#3460DC" name="eye" /> View Event Details
          </button>
          {/* <button
            onClick={() => {
              setOpen(!open);
            }}
            className="flex justify-center font-medium items-center w-[120px] h-[43px] gap-2 px-4 py-2 text-shades-50 rounded-md bg-primary-500"
          >
            <Icon size="18px" name="plus" />
            Actions
          </button> */}
          {/* {open && (
            <div
              id={id}
              aria-hidden="true"
              className="fixed top-0 left-0 right-0 z-50 flex h-screen overflow-y-auto md:inset-0 h-modal md:h-full"
              onClick={() => {
                setOpen((prev) => !prev);
              }}
            >
              <div
                className="grid grid-flow-row p-2 gap-4 rounded-lg justify-start text-start absolute top-32 right-12 w-[280px] h-[158px] bg-shades-50"
                onClick={(e) => e.stopPropagation()}
              >
                <span className="font-medium text-primary-400">Actions</span>
                <button
                  className="flex gap-2 text-start"
                  onClick={() => {
                    setOpen((prev) => !prev);
                    setOpenModal((prev) => !prev);
                    setActionModalType("Create Ticket Group");
                  }}
                >
                  <Icon color="#3460DC" name="plus-circle" /> Add Ticket Group
                </button>
                <button
                  className="flex gap-2 text-start"
                  onClick={() => {
                    setOpen((prev) => !prev);
                    setOpenModal((prev) => !prev);
                    setActionModalType("Add Voucher Code");
                  }}
                >
                  <Icon color="#3460DC" name="file" /> Add Voucher Code
                </button>
                <button
                  className="flex gap-2 text-start"
                  onClick={() => {
                    setOpen((prev) => !prev);
                    setOpenModal((prev) => !prev);
                    setActionModalType("Event Details");
                  }}
                >
                  <Icon color="#3460DC" name="eye" /> View/Edit Event Details
                </button>
              </div>
            </div>
          )} */}
        </div>
      </div>
      {data.eventTickets?.map((eventTicket: EventTicket) => (
        <Accordion key={eventTicket.id} label={eventTicket.name}>
          <TicketsTable eventTicket={eventTicket} />
        </Accordion>
      ))}
      <ActionsModal
        data={data}
        open={openModal}
        setOpen={setOpenModal}
        modalForm={actionModalType}
        setActionModalType={setActionModalType}
      />
    </div>
  );
};

export default SelectedEventPage;
