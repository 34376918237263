import React from "react";

const EventSvg = () => {
  return (
    <svg
      width="64"
      height="74"
      viewBox="0 0 64 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.7"
        d="M3.6236 0.848622C11.9606 7.95878 33.947 24.1705 61.8709 24.945C62.442 24.9621 62.9837 25.2013 63.3812 25.6116C63.7788 26.0217 64.0007 26.571 64 27.1424V71.3872C64.0002 71.9517 63.7835 72.4946 63.3945 72.9035C63.0055 73.3124 62.474 73.5561 61.9102 73.5841C53.5147 74.0018 21.678 73.9532 0.582726 51.3679C0.206498 50.9623 -0.00164963 50.4289 9.84687e-06 49.8758V2.52422C-0.000227222 2.10437 0.119733 1.69306 0.345896 1.33912C0.57206 0.985411 0.894704 0.703533 1.27591 0.527391C1.65688 0.351012 2.08076 0.287479 2.49682 0.344612C2.91288 0.401509 3.3038 0.57623 3.6236 0.848622Z"
        fill="#1D4ED8"
      />
      <path
        d="M6.78613 13.8867C6.78613 13.8867 13.365 20.2793 22.3573 24.2502"
        stroke="#F9FAFB"
        strokeWidth="7.4328"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        opacity="0.4"
        d="M6.78613 21.6367C6.78613 21.6367 15.942 30.1769 27.8528 33.3481"
        stroke="#F9FAFB"
        strokeWidth="7.4328"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M5.42188 49.9758V31.4951L11.9012 36.1317V55.059L5.42188 49.9758Z"
        fill="#F9FAFB"
      />
      <path
        d="M16.3584 57.4434V44.2759L22.8377 48.1508V61.1675L16.3584 57.4434Z"
        fill="#F9FAFB"
      />
      <path
        d="M44.0643 67.7512V54.5837L37.585 53.6968V66.7135L44.0643 67.7512Z"
        fill="#F9FAFB"
      />
      <path
        d="M54.7069 68.3152V45.2612H48.2275V68.1647L54.7069 68.3152Z"
        fill="#F9FAFB"
      />
      <path
        d="M26.9717 63.2125V57.1924L33.451 59.0145V65.559L26.9717 63.2125Z"
        fill="#F9FAFB"
      />
    </svg>
  );
};

export default EventSvg;
