import React from "react";
import { Field, Formik, Form } from "formik";
import { signupValidationSchema } from "../../../../formik/auth/ValidationSchema";
import CustomInput from "../../../../components/CustomInput";

const AddMemberModal = () => {
  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={signupValidationSchema}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {(formik) => (
        <Form>
          <div className="p-4">
            <div className="p-1">
              <div className="mb-2 text-md">First Name</div>
              <div>
                {/* <input type="text" className="w-64 shadow-xl" /> */}
                <Field
                  name="Firtname"
                  placeholder="First Name"
                  component={CustomInput}
                />
              </div>
            </div>
            <div className="p-1">
              <div className="mb-2 text-md">Last Name</div>
              <div>
                <Field
                  name="Lastname"
                  placeholder="Last Name"
                  component={CustomInput}
                />
              </div>
            </div>
            <div className="p-1">
              <div className="mb-2 text-md">E-mail</div>
              <div>
                <Field
                  name="email"
                  placeholder="Email Address"
                  component={CustomInput}
                />
              </div>
            </div>
            <div className="p-1">
              <div className="mb-2 text-md">Role</div>
              <div>
                <Field name="Role" placeholder="Role" component={CustomInput} />
              </div>
            </div>
            <div className="p-1 mt-1 text-center rounded-md bg-blue-1100">
              <button className="text-white">Add New User</button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddMemberModal;
