import React from "react";

function TicketQuantityForm() {
  return (
    <div className="w-full pr-6">
      <div>
        <h1 className="my-4 font-Clash text-xl">Ticket Quantity</h1>
      </div>
      <div className="grid grid-rows-1  w-1/2 h-12 ">
        <input
          placeholder="Ticket Quantity"
          className=" h-12 shadow-md pl-4 rounded-sm font-semibold"
        />
      </div>
    </div>
  );
}

export default TicketQuantityForm;
