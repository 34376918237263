import React from "react";
import { Link } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { CustomError } from "../../../services/auth.api";
import CustomInput from "../../../components/CustomInput";
import { signupValidationSchema } from "../../../formik/auth/ValidationSchema";
import { signupInitialValues } from "../../../formik/auth/InitialValues";
import { SignUpProps } from "../../../interfaces/auth/Interface";

const SignUp: React.FC<SignUpProps> = ({
  signupHandleSubmit,
  isError,
  error,
  isModal,
}) => {
  return (
    <Formik
      initialValues={signupInitialValues}
      validationSchema={signupValidationSchema}
      onSubmit={signupHandleSubmit}
    >
      {() => (
        <Form>
          <div className="items-center flex-flex-col">
            <div className="mb-2 text-3xl font-bold text-center lg:mb-6">SIGN UP</div>
            <Field
              name="email"
              placeholder="Email Address"
              component={CustomInput}
            />

            <Field
              name="password"
              type="password"
              placeholder="Password"
              component={CustomInput}
            />

            <Field
              name="firstName"
              component={CustomInput}
              placeholder="First Name"
            />

            <Field
              name="lastName"
              component={CustomInput}
              placeholder="Last Name"
            />

            <Field
              name="mobileNumber"
              component={CustomInput}
              placeholder="Mobile Number (09XXXXXXXXX)"
            />

            <div className="mt-4">
              <button
                type="submit"
                className="p-2 font-bold text-white rounded-sm bg-blue-1100 w-96"
              >
                SIGN UP
              </button>
            </div>

            {isError && error && (
              <div className="pt-2 text-red-500">
                <h3 className="font-semibold">
                  Error {(error as CustomError).status}
                </h3>
                <ul>
                  <li>{(error as CustomError).data.message}</li>
                </ul>
              </div>
            )}
            {!isModal ? (
              <div className="flex justify-center mt-4">
                <div className="mr-2">Already have an Account?</div>
                <Link to="/signin">
                  <div className="underline">Sign In</div>
                </Link>
              </div>
            ) : null}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignUp;
