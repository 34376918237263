import React from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Label } from "../../../components/Common/Label";
import { Input } from "../../../components/Common/Input";
import { EventTicket } from "../../../models/event-ticket.model";
import { useUpdatePartnerEventTicketMutation } from "../../../services/partner.api";

interface ComponentProps {
  eventTicket: EventTicket;
}

interface FormValues {
  id:string;
  name: string;
  quantity: number | string;
  price: number ;
  type: string;
  seatType: string;
  section: string;
  row: string;
  published: boolean
}

export const EditTicketForm: React.FC<ComponentProps> = ({ eventTicket }) => {
  const [update, {data}] = useUpdatePartnerEventTicketMutation()
  const initialValues: FormValues = {
    id:eventTicket.id || "",
    name: eventTicket.name || "",
    quantity: eventTicket.quantity || "",
    price: eventTicket.price || 0,
    type: eventTicket.type || "",
    seatType: eventTicket.seatType || "",
    section: eventTicket.section || "",
    row: eventTicket.row || "",
    published: eventTicket.published || false
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Ticket Name is required."),
    quantity: Yup.string().required("Quantity is required."),
    price: Yup.string().required("Price is required."),
    seatType: Yup.string().required("Seat Type is required."),
    section: Yup.string().required("Section is required."),
    row: Yup.string().required("Row is required."),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    update(values)
    // onSubmit(values);
  };

  return (
    <div>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <div className="grid gap-2 px-4">
                <div className="grid grid-rows-2 gap-4">
                  <div>
                    <Label htmlFor="name">Ticket Name</Label>
                    <Field name="name">
                      {({ field }: FieldProps) => (
                        <Input
                          placeholder="ex. General Admission"
                          id="name"
                          {...field}
                        />
                      )}
                    </Field>
                  </div>
                  <div>
                    <Label htmlFor="quantity">Quantity</Label>
                    <Field name="quantity">
                      {({ field }: FieldProps) => (
                        <Input placeholder="ex. 500" id="quantity" {...field} />
                      )}
                    </Field>
                  </div>
                  <div>
                    <Label htmlFor="price">Price</Label>
                    <Field name="price">
                      {({ field }: FieldProps) => (
                        <Input
                          placeholder="ex. P690.00"
                          id="price"
                          {...field}
                        />
                      )}
                    </Field>
                  </div>
                  <div>
                    <Label htmlFor="seatType">Seat Type</Label>
                    <Field name="seatType">
                      {({ field }: FieldProps) => (
                        <Input
                          placeholder="ex. General Admission"
                          id="seatType"
                          {...field}
                        />
                      )}
                    </Field>
                  </div>
                  <div>
                    <Label htmlFor="section">Section</Label>
                    <Field name="section">
                      {({ field }: FieldProps) => (
                        <Input placeholder="ex. Z" id="section" {...field} />
                      )}
                    </Field>
                  </div>
                  <div>
                    <Label htmlFor="row">Row</Label>
                    <Field name="row">
                      {({ field }: FieldProps) => (
                        <Input placeholder="ex. 14" id="row" {...field} />
                      )}
                    </Field>
                  </div>
                  <button
                    type="submit"
                    className="py-2 text-sm font-medium text-white border rounded bg-primary-500"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
