import React from "react";

interface ComponentProps {
  namex?: string;
}

type TExtendedProps = ComponentProps & React.HTMLProps<HTMLLabelElement>;
export const Label: React.FC<TExtendedProps> = ({ children, ...props }) => {
  return (
    <label className="text-lg font-semibold" {...props}>
      {children}
    </label>
  );
};
