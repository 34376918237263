import dayjs from "dayjs";
import React from "react";
import { PaginationContainer } from "../../components";
import { Order } from "../../models/order.model";
import { useGetPartnerOrdersQuery } from "../../services/partner.api";

const OrderPage = () => {
  const [skip, setSkip] = React.useState(1);
  const [take, setTake] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const { data } = useGetPartnerOrdersQuery({
    skip,
    take,
  });
  React.useEffect(() => {
    setSkip((page - 1) * take);
  }, [page, take]);
  return (
    <div className="p-[16px] bg-primary-50 flex-grow flex flex-col">
      {/* <div className="h-[80px] bg-blue-1500 text-white p-[8px] rounded-md grid gap-5 grid-cols-2 items-center">
        <div className="flex flex-col">
          <span className="text-xs">Total Amount</span>
          <span className="text-2xl font-medium">00</span>
        </div>
        <div className="flex flex-col">
          <span className="text-xs">Total Orders</span>
          <span className="text-2xl font-medium">00</span>
        </div>
      </div> */}
      <div className="bg-neutral-50  p-[24px] rounded-md flex-grow mt-[16px]">
        <PaginationContainer
          totalCount={data?.count || 0}
          currentPage={page}
          pageSize={take}
          onPageChange={(data) => {
            setPage(data);
          }}
        >
          <ul className="grid grid-cols-7 font-semibold text-[23px] leading-[28px] gap-1 text-center">
            <li>ID</li>
            <li>Order Date</li>
            <li>Event</li>
            <li>Venue</li>
            <li>Date</li>
            <li>Quantity</li>
            <li>Total</li>
          </ul>
          <div className=" pt-[28px] text-[18px] leading-[22px] ">
            {data &&
              data.data.map(
                ({
                  id,
                  eventName,
                  eventVenue,
                  eventDate,
                  orderDate,
                  quantity,
                  total,
                }: Order) => (
                  <ul
                    className="grid grid-cols-7 gap-1 mb-2 text-center"
                    key={id + "order"}
                  >
                    <li>{id}</li>
                    <li>{dayjs(orderDate).format("MM/DD/YYYY hh:mm A")}</li>
                    <li>{eventName}</li>
                    <li>{eventVenue}</li>
                    <li>{dayjs(eventDate).format("MMM DD, YYYY hh:mm A")}</li>
                    <li>{quantity}</li>
                    <li>P {total}.00</li>
                  </ul>
                )
              )}
          </div>
        </PaginationContainer>
      </div>
    </div>
  );
};

export default OrderPage;
