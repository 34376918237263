import React from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { EventList } from "../../features/Event";
import { CustomSearchBar } from "../../components/CustomSearchBar";

const InventoryPage = () => {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);

  return (
    <div className="flex flex-grow bg-primary-50">
      <div className="flex flex-grow">
        {/* <div className="w-[220px] border-r ">
          {eventsData.map((value) => (
            <Link key={value.id} to={value.id}>
              <div
                className={`border-b p-1 font-semibold  ${
                  id === value.id ? "bg-blue-1100 text-white" : "bg-shades-50"
                }`}
              >
                <h2 className="text-base">{value.name}</h2>
                <p className="text-xs">{value.venue.name}</p>
                <p className="text-xs">{value.dateTime}</p>
              </div>
            </Link>
          ))}
        </div> */}
        <EventList id={id || ""} search="" />
        <div className="flex-1">
          <div>
            <CustomSearchBar open={open} setOpen={setOpen} />
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default InventoryPage;
