import React from "react";
import "./index.css";
import { createRoot } from "react-dom/client";
import { store } from "./app/store";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ErrorBoundary } from "./components/Error";
import { AuthContainer } from "./features/Authentication/AuthContainer";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>
        <AuthContainer>
          <App />
        </AuthContainer>
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>
);
