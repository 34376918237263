import React from "react";
import OverallSvg from "../assets/OverallSvg";

interface ComponentProps {
  overAllSales: number;
}
const OverallTicketSales: React.FC<ComponentProps> = ({ overAllSales }) => {
  return (
    <>
      <div className="grid flex-1 ">
        <div className="grid gap-4 lg:grid-cols-2">
          <div className="">
            <h2 className="text-xl font-[500]">Overall Ticket Sales</h2>
            <p className="text-5xl font-[500]">P {overAllSales}.00</p>
          </div>
          {/* <div className="flex flex-row items-center lg:flex-col">
            <h2 className="text-4xl font-[600]">10%</h2>
            <p className="font-[500] text-xl">Since last week</p>
          </div> */}
          {/* <div className="h-5 overflow-hidden bg-white rounded-full lg:col-span-2">
            <div className="bg-lightBlue-300 w-[75%] h-full rounded-full" />
          </div> */}
        </div>
      </div>
      <OverallSvg />
    </>
  );
};

export default OverallTicketSales;
