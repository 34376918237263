import React from "react";
import { Modal } from "../../../components/Modal";
import { Event } from "../../../models/event.model";
import { AddVoucherForm } from "./AddVoucherForm";
import { CreateTicketForm } from "./CreateTicketForm";
import { EditEventForm } from "./EditEventForm";
import { ViewEventForm } from "./ViewEventForm";

interface ComponentProps {
  data: Event;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalForm: string;
  setActionModalType: Function;
}

export const ActionsModal: React.FC<ComponentProps> = ({
  open,
  setOpen,
  modalForm = "",
  setActionModalType,
  data,
}) => {
  const actionTypeGetter = () => {
    switch (modalForm) {
      case "Create Ticket Group":
        return <CreateTicketForm />;
      case "Add Voucher Code":
        return <AddVoucherForm />;
      case "Event Details":
        return (
          <ViewEventForm data={data} setActionModalType={setActionModalType} />
        );
      case "Edit Event Details":
        return <EditEventForm data={data} />;
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={modalForm}
      size={
        modalForm === "Event Details" || modalForm === "Edit Event Details"
          ? "m"
          : "s"
      }
    >
      {actionTypeGetter()}
    </Modal>
  );
};
