import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import { Event } from "../../models/event.model";
import { useGetPartnerEventsQuery } from "../../services/partner.api";

interface ComponentProps {
  id: string;
  search: string;
}
const EventList: React.FC<ComponentProps> = ({ id, search }) => {
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [take] = React.useState(5);
  const { data, isLoading } = useGetPartnerEventsQuery({
    skip,
    take,
    search: search || "",
  });
  return (
    <div className="w-[220px] border-r border-neutral-500">
      {data &&
        data?.data.map((event: Event) => (
          <Link key={event.id} to={event.id}>
            <div
              className={`border-b border-neutral-500 p-1 font-semibold  ${
                id === event.id ? "bg-blue-1100 text-white" : "bg-shades-50"
              }`}
            >
              <h2 className="text-base">{event.name}</h2>
              <p className="text-xs">{event.venue.name}</p>
              <p className="text-xs">
                {dayjs(event.dateTime).format("MM/DD/YYYY hh:mm")}
              </p>
            </div>
          </Link>
        ))}
    </div>
  );
};

export default EventList;
