import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { ChevronRight, ChevronLeft } from "ts-react-feather-icons";
import "./Calendar.css";

const Calendar = () => {
  const [startDate, setStartDate] = React.useState<Date>(moment().toDate());

  return (
    <div className="h-full p-5 calendar_container">
      <DatePicker
        inline
        selected={startDate}
        onChange={(date: Date) => setStartDate(date)}
        calendarClassName="w-full h-full"
        dayClassName={() => "md:text-xs text-base"}
        renderCustomHeader={({ increaseMonth, date, decreaseMonth }) => (
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2 text-sm md:text-xs text-primary-500">
              <h1 className="text-xl font-medium md:text-lg ">Calendar</h1>
              <p>{moment(date).format("YYYY")}</p>
              <p>{moment(date).format("MMMM")}</p>
            </div>
            <div>
              <button onClick={() => decreaseMonth()}>
                <ChevronLeft size={20} />
              </button>
              <button onClick={() => increaseMonth()}>
                <ChevronRight size={20} />
              </button>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default Calendar;
