import React from "react";
import PastEventSvg from "../assets/PastEventSvg";

interface ComponentProps {
  pastEvents: number
}
const PastEvents: React.FC<ComponentProps> = ({pastEvents}) => {
  return (
    <>
      <div className="flex-1">
        <h2 className="text-4xl font-[600] text-blue-1100">{pastEvents}</h2>
        <p className="text-xl font-[500]">Past Events</p>
      </div>
      <PastEventSvg />
    </>
  );
};

export default PastEvents;
