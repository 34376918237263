import React from "react";
import EventSvg from "../assets/EventSvg";

interface ComponentProps {
  totalEvents: number;
}
const TotalEvents: React.FC<ComponentProps> = ({totalEvents}) => {
  return (
    <>
      <div className="flex-1">
        <h2 className="text-4xl font-[600] text-blue-1100">{totalEvents}</h2>
        <p className="text-xl font-[500]">Total Events</p>
      </div>
      <EventSvg />
    </>
  );
};

export default TotalEvents;
