import React from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Label } from "../../../components/Common/Label";
import { Input } from "../../../components/Common/Input";
import DatePicker from "../../../components/Common/DatePicker";

interface ComponentProps {}

interface FormValues {
  name: string;
  type: string;
  expiredAt: string;
  discount: string;
  quantity: string;
  code: string;
}

export const AddVoucherForm: React.FC<ComponentProps> = () => {
  const initialValues: FormValues = {
    name: "",
    type: "",
    expiredAt: "",
    discount: "",
    quantity: "",
    code: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Voucher Name is required."),
    type: Yup.string().required("Voucher Type is required."),
    expiredAt: Yup.string().required("Expiration date is required."),
    discount: Yup.string().required("Seat Type is required."),
    quantity: Yup.string().required("Section is required."),
    code: Yup.string().required("Row is required."),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    // onSubmit(values);
  };

  return (
    <div>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <div className="grid gap-2 px-4">
                <div className="grid grid-rows-2 gap-4">
                  <div>
                    <Label htmlFor="name">Voucher Name</Label>
                    <Field name="name">
                      {({ field }: FieldProps) => (
                        <Input
                          placeholder="ex. First Voucher Code"
                          id="name"
                          {...field}
                        />
                      )}
                    </Field>
                  </div>
                  <div>
                    <Label htmlFor="type">Type</Label>
                    <Field name="type">
                      {({ field }: FieldProps) => (
                        <Input
                          placeholder="ex. Referral"
                          id="type"
                          {...field}
                        />
                      )}
                    </Field>
                  </div>
                  <div>
                    <Label htmlFor="expiredAt">Expired At</Label>
                    <Field name="expiredAt">
                      {({ field }: FieldProps) => (
                        <DatePicker
                          name="expiredAt"
                          placeholder="ex. 09/23/2022"
                          minDate={new Date()}
                        />
                      )}
                    </Field>
                  </div>
                  <div>
                    <Label htmlFor="discount">Discount Percentage</Label>
                    <Field name="discount">
                      {({ field }: FieldProps) => (
                        <Input
                          placeholder="ex. General Admission"
                          id="discount"
                          {...field}
                        />
                      )}
                    </Field>
                  </div>
                  <div>
                    <Label htmlFor="quantity">Quantity</Label>
                    <Field name="quantity">
                      {({ field }: FieldProps) => (
                        <Input placeholder="ex. 50" id="quantity" {...field} />
                      )}
                    </Field>
                  </div>
                  <div>
                    <Label htmlFor="code">Code</Label>
                    <Field name="code">
                      {({ field }: FieldProps) => (
                        <Input
                          placeholder="ex. GSMFIRST"
                          id="code"
                          {...field}
                        />
                      )}
                    </Field>
                  </div>
                  <button
                    type="submit"
                    className="py-2 font-medium text-white text-sm bg-primary-500 border rounded"
                  >
                    Add Code
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
