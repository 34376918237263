import dayjs from "dayjs";
import React from "react";
import { Icon } from "ts-react-feather-icons";
import { Event } from "../../../models/event.model";

interface ComponentProps {
  setActionModalType: Function;
  data: Event;
}

export const ViewEventForm: React.FC<ComponentProps> = ({
  setActionModalType,
  data,
}) => {
  const {
    name,
    dateTime,
    description,
    groups,
    performers,
    venue: { name: venue },
  } = data;

  return (
    <div className="grid grid-flow-row px-4">
      <div className="grid grid-cols-5">
        <div className="grid col-span-4">
          <span className="text-xs">Event Name</span>
          <span className="font-medium text-[28px]">{name}</span>
          <span className="text-xs">Date and Time</span>
          <span className="font-medium text-[28px]">
            {dayjs(dateTime).format("MM/DD/YYYY hh:mm")}
          </span>
          <span className="text-xs">Venue</span>
          <span className="font-medium text-[28px]">{venue}</span>
        </div>
        <div className="grid col-span-1 gap-2">
          <div className="flex items-center justify-center mr-4 overflow-hidden bg-black rounded w-full h-[120px]">
            <div className="bg-black " />
          </div>
          <button
            onClick={() => {
              setActionModalType("Edit Event Details");
            }}
            className="flex justify-center font-medium items-center h-[43px] gap-2 px-4 py-2 text-shades-50 rounded-md bg-primary-500"
          >
            <Icon size="18px" name="edit" />
            Edit
          </button>
        </div>
      </div>

      <div className="grid gap-3 py-2">
        <div className="grid gap-3">
          <>
            <span className="text-xs">Main Performer</span>
            <div className="flex flex-wrap gap-2">
              {performers &&
                performers.map((performer) => (
                  <div className="flex justify-center w-fit font-medium items-center text-sm gap-2 px-4 py-2 text-shades-50 rounded-full bg-primary-500">
                    {/* <>{performer}</> */}
                  </div>
                ))}
            </div>
          </>
        </div>
        <div className="grid gap-3 ">
          <>
            <span className="text-xs">Performers</span>
            <div className="flex flex-wrap gap-2">
              {performers &&
                performers.map((performer) => (
                  <div className="flex justify-center w-fit font-medium items-center text-sm gap-2 px-4 py-2 text-shades-50 rounded-full bg-primary-500">
                    {/* <>{performer}</> */}
                  </div>
                ))}
            </div>
          </>
        </div>
        <div className="grid gap-3 ">
          <>
            <span className="text-xs">Groups</span>
            <div className="flex flex-wrap gap-2">
              {groups &&
                groups.map((group) => (
                  <div className="flex justify-center w-fit font-medium items-center text-sm gap-2 px-4 py-2 text-shades-50 rounded-full bg-primary-500">
                    {/* <>{group}</> */}
                  </div>
                ))}
            </div>
          </>
        </div>
      </div>

      <div className="grid py-2">
        <span className="text-xs">Event Description</span>
        <div className="flex gap-2">
          <span className="font-medium text-[28px]">{description}</span>
        </div>
      </div>
    </div>
  );
};
