import React from "react";
import AddIconBlack from "../../assets/images/VectorBlack.png";

function TicketTypeForm() {
  return (
    <div className="w-full">
      <div>
        <h1 className="my-4 font-Clash text-xl">Ticket Type(s)</h1>
      </div>
      <div className=" h-32 flex justify-around ">
        <div className="grid grid-rows-2 grid-flow-col mr-5 w-1/2">
          <input
            placeholder="Ticket Type"
            className=" h-12 shadow-md pl-4 rounded-sm font-semibold"
          />
          <input
            placeholder="Ticket Price"
            className=" h-12 shadow-md pl-4 rounded-sm font-semibold "
          />
        </div>
        <div className=" h-12 w-1/2 ml-1 flex justify-start items-center pl-2">
          <button className="h-9 w-9 bg-altevBLue rounded-full">
            <img src={AddIconBlack} alt="icon" />
          </button>
          <h1 className="pl-4 font-Clash text-gray-300">
            Add Another Ticket Type
          </h1>
        </div>
      </div>
      <div className="w-full h-24 flex justify-end items-center">
        <button className="bg-lightBlue-500 h-10 w-40 rounded-2xl text-white font-medium text-lg">
          SUBMIT
        </button>
      </div>
    </div>
  );
}

export default TicketTypeForm;
