import React from "react";
import "./css/clash-display.css";
import { Route, Routes, Outlet, Link, useLocation } from "react-router-dom";
import CreateEventPage from "./pages/CreateEvent";
import SalesReportPage from "./pages/SalesReport";
import Sidebar from "./layouts/Sidebar";
import Body from "./layouts/Body";
import DashboardLogo from "./assets/images/dashboardicon.png";
import SalesReportIcon from "./assets/images/salesreporticon.png";
import CreateEventIcon from "./assets/images/createeventicon.png";
import LogoutIcon from "./assets/images/logouticon.png";
import TicketNation from "./assets/images/Ticketnationlogo.png";
import {
  DashboardPage,
  InventoryPage,
  OrderPage,
  SelectedEventPage,
} from "./pages";
import { ProtectedRoute } from "./routes";
import { useAppSelector } from "./app/hooks";
import { authSelect } from "./features/Authentication/authSlice";
import SignInPage from "./pages/SignIn";
import SignUpPage from "./pages/SignUp";
import Success from "./pages/SignUp/Success";
import ForgotPasswordPage from "./pages/ForgotPassword";
import ResetPasswordPage from "./pages/ForgotPassword/ResetPassword";
import PasswordResetSuccess from "./pages/ForgotPassword/Success";
import Error404Page from "./pages/Error404";
import VerificationPage from "./pages/Verification";
import VerificationSuccess from "./pages/Verification/Success";
import ProfilePage from "./pages/Profile";
import ProfileNavPage from "./pages/Profile/Components/ProfileNavPage";
import Generalinfo from "./pages/Profile/Components/ProfilePage/Generalinfo";
import MembersNavPage from "./pages/Profile/Components/MembersNavPage";
import Password from "./pages/Profile/Components/ProfilePage/Password";
import EwalletNavPage from "./pages/Profile/Components/EwalletNavPage";
import ProfileIcons from "./assets/images/ProfileIcons.png";
import { Logout } from "./features/Authentication";
interface LocationState {
  from?: string;
}
const App: React.FC = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const { user } = useAppSelector(authSelect);
  return (
    <div style={{ fontFamily: "ClashDisplay" }}>
      <Routes>
        <Route
          element={
            <ProtectedRoute
              isAllowed={user === null}
              redirectPath={state?.from || "/"}
            />
          }
        >
          <Route path="/signin" element={<SignInPage />} />
          <Route path="signup">
            <Route index element={<SignUpPage />} />
            <Route path="success" element={<Success />} />
          </Route>
        </Route>
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="reset-password">
          <Route index element={<ResetPasswordPage />} />
          <Route path="success" element={<PasswordResetSuccess />} />
          <Route path="*" element={<Error404Page />} />
        </Route>
        <Route
          element={
            <ProtectedRoute
              isAllowed={user !== null}
              redirectPath={"/signin"}
            />
          }
        >
          <Route
            element={
              <div className="flex flex-grow h-screen bg-blue-1100">
                <Sidebar className="flex flex-col justify-between text-white ">
                  <div className="flex justify-center">
                    <div className="grid gap-8 text-white lg:gap-2">
                      <div className="hidden lg:block">
                        <img src={TicketNation} alt="logo" />
                      </div>
                      <div className="flex items-center py-2 mt-6">
                        <Link to="/" className="flex">
                          <div className="lg:mr-4">
                            <img src={DashboardLogo} alt="DashboardLogo" />
                          </div>
                          <div className="hidden font-light lg:block">
                            Dashboard
                          </div>
                        </Link>
                      </div>
                      <div className="flex py-2">
                        <Link to="/inventory" className="flex">
                          <div className="lg:mr-4">
                            <img src={CreateEventIcon} alt="CreateEventIcon" />
                          </div>
                          <div className="hidden font-light lg:block">
                            Inventory
                          </div>
                        </Link>
                      </div>
                      {/* <div className="flex py-2">
                        <Link to="/sales-report" className="flex">
                          <div className="lg:mr-4">
                            <img src={SalesReportIcon} alt="SalesReportIcon" />
                          </div>
                          <div className="hidden font-light lg:block">
                            Sales Report
                          </div>
                        </Link>
                      </div> */}
                      <div className="flex py-2">
                        <Link to="/orders" className="flex">
                          <div className="lg:mr-4">
                            <img src={SalesReportIcon} alt="Order-Icon" />
                          </div>
                          <div className="hidden font-light lg:block">
                            Order
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <Link
                      to="/profile-page/profilePage"
                      className="flex items-center px-3 py-2 text-[#8D97B2] bg-white rounded-sm"
                    >
                      <div className="lg:mr-2">
                        <img src={ProfileIcons} alt="ProfileIcon" />
                      </div>
                      <div className="hidden font-light lg:block">John Doe</div>
                    </Link>
                    <Logout />
                  </div>
                  {/* <div className="flex justify-center pt-2 mb-12">
                    <div className="lg:mr-4">
                      <img src={LogoutIcon} alt="LogoutIcon" />
                    </div>
                    <div className="hidden font-light lg:block">Sign Out</div>
                  </div> */}
                </Sidebar>
                <Body>
                  <Outlet />
                </Body>
              </div>
            }
          >
            <Route path="/" element={<DashboardPage />} />
            <Route path="/inventory" element={<InventoryPage />}>
              <Route path=":id" element={<SelectedEventPage />} />
            </Route>
            <Route path="/create-event" element={<CreateEventPage />} />
            <Route path="/sales-report" element={<SalesReportPage />} />
            <Route path="/orders" element={<OrderPage />} />
            <Route path="profile-page" element={<ProfilePage />}>
              <Route path="profilepage" element={<ProfileNavPage />} />
              <Route path="member" element={<MembersNavPage />} />
              <Route path="ewallet" element={<EwalletNavPage />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </div>
  );
};

export default App;
