import React from "react";

interface ComponentProps {
  variant?: "primary" | "secondary";
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}
const Container: React.FC<ComponentProps> = ({
  children,
  variant = "primary",
  style,
  className,
}) => {
  return (
    <div
      style={style}
      className={`rounded-md shadow-md ${
        variant === "primary" && "bg-white"
      } 
      ${
        variant === "secondary" && "bg-blue-1100"
      }
      
      ${className}`}
    >
      {children}
    </div>
  );
};

export default Container;
