import React from "react";
import { Icon } from "ts-react-feather-icons";

interface ComponentProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  size?: "s" | "m" | "l" | "xl";
  children?: JSX.Element | JSX.Element[];
  id?: string;
  onClose?: () => void;
}
export const Modal: React.FC<ComponentProps> = ({
  children,
  open,
  setOpen,
  title,
  size,
  id = "modal",
  onClose,
}) => {
  const getSize = () => {
    switch (size) {
      case "s":
        return "lg:w-[500px]";
      case "m":
        return "lg:w-2/3";
      case "l":
        return "lg:w-11/12";
      case "xl":
        return "lg:w-full";
      default:
        return "lg:w-1/3";
    }
  };

  return (
    <>
      {open ? (
        <div
          id={id}
          aria-hidden="true"
          className="fixed top-0 left-0 right-0 z-50 flex items-center justify-center  h-screen overflow-y-auto bg-gray-400 bg-opacity-50 md:inset-0 h-modal md:h-full"
          onClick={() => {
            if (onClose) onClose();
            setOpen(false);
          }}
        >
          <div
            className={`relative max-w-2xl rounded-lg min-h-32 pb-4 bg-shades-50 ${getSize()} w-screen`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="flex items-center justify-between px-4 py-4 text-primary-400 ">
              <span className="text-2xl font-semibold">{title}</span>
              <button
                onClick={() => {
                  if (onClose) onClose();
                  setOpen(false);
                }}
              >
                <Icon name="x" />
              </button>
            </div>
            <div>{children}</div>
          </div>
        </div>
      ) : null}
    </>
  );
};
